var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.emails.data,
      "total": _vm.emails.total,
      "loading": _vm.loading.getEmails,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.getEmails,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1
    },
    scopedSlots: _vm._u([{
      key: "destinations",
      fn: function fn(slotProps) {
        return [_vm._v(" " + _vm._s(slotProps.data.destinations.join(', ')) + " ")];
      }
    }, {
      key: "createdAt",
      fn: function fn(slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("date")(slotProps.data.createdAt, 'Do MMM YYYY - HH:mm:ss')) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(slotProps) {
        return [_c('button', {
          staticClass: "btn btn-primary",
          on: {
            "click": function click($event) {
              _vm.modals.email = slotProps.data;
            }
          }
        }, [_vm._v("View Email")])];
      }
    }])
  }), _c('ConfirmModal', {
    attrs: {
      "hide-submit": "",
      "open": _vm.modals.email,
      "title": "View Email"
    },
    on: {
      "close": function close($event) {
        _vm.modals.email = false;
      }
    }
  }, [_vm.modals.email ? _c('div', [_c('div', {
    staticClass: "mb-3"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("To")]), _vm._l(_vm.modals.email.destinations, function (d) {
    return _c('p', {
      key: d,
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(d))]);
  })], 2), _c('div', {
    staticClass: "mb-3"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("From")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.modals.email.from))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("Received At")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.modals.email.createdAt, 'Do MMM YYYY - HH:mm:ss')))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("Subject")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.modals.email.subject))])]), _c('h4', {
    staticClass: "font-w600 mb-2"
  }, [_vm._v("Body")]), _c('div', {
    staticClass: "block block-rounded bg-light"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_vm._v(" " + _vm._s(_vm.modals.email.body) + " ")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('h4', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("Attachments")]), _vm._l(_vm.modals.email.attachments, function (attachment) {
    return _c('div', {
      key: attachment._id
    }, [_c('a', {
      staticClass: "mb-2 d-block",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDownloadAttachment(_vm.modals.email, attachment);
        }
      }
    }, [_vm._v(_vm._s(attachment.filename))])]);
  })], 2)]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }