<template>
  <div class="block block-rounded block-fx-pop">
    <div class="block-content block-content-full">
      <DataTable
        :items="emails.data"
        :total="emails.total"
        :loading="loading.getEmails"
        :columns="columns"
        :page-size="20"
        :fetch-items="getEmails"
        default-sort-key="createdAt"
        :default-sort-direction="-1"
      >
        <template v-slot:destinations="slotProps">
          {{ slotProps.data.destinations.join(', ') }}
        </template>
        <template v-slot:createdAt="slotProps">
          {{ slotProps.data.createdAt | date('Do MMM YYYY - HH:mm:ss') }}
        </template>

        <template v-slot:actions="slotProps">
          <button class="btn btn-primary" @click="modals.email = slotProps.data">View Email</button>
        </template>
      </DataTable>

      <ConfirmModal hide-submit :open="modals.email" title="View Email" @close="modals.email = false">
        <div v-if="modals.email">
          <div class="mb-3">
            <h4 class="font-w600 mb-1">To</h4>
            <p v-for="d in modals.email.destinations" :key="d" class="mb-0">{{ d }}</p>
          </div>

          <div class="mb-3">
            <h4 class="font-w600 mb-1">From</h4>
            <p class="mb-0">{{ modals.email.from }}</p>
          </div>

          <div class="mb-3">
            <h4 class="font-w600 mb-1">Received At</h4>
            <p class="mb-0">{{ modals.email.createdAt | date('Do MMM YYYY - HH:mm:ss') }}</p>
          </div>

          <div class="mb-3">
            <h4 class="font-w600 mb-1">Subject</h4>
            <p class="mb-0">{{ modals.email.subject }}</p>
          </div>

          <h4 class="font-w600 mb-2">Body</h4>
          <div class="block block-rounded bg-light">
            <div class="block-content block-content-full">
              {{ modals.email.body }}
            </div>
          </div>

          <div class="mb-3">
            <h4 class="font-w600 mb-1">Attachments</h4>
            <div v-for="attachment in modals.email.attachments" :key="attachment._id">
              <a href="#" class="mb-2 d-block" @click.prevent="onClickDownloadAttachment(modals.email, attachment)">{{ attachment.filename }}</a>
            </div>
          </div>
        </div>
      </ConfirmModal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTable from '@/components/DataTable';
import ConfirmModal from '@/components/ConfirmModal';

import { serviceOptions } from '../../lib/automation';

export default {
  name: 'AdminLog',
  components: {
    ConfirmModal,
    DataTable
  },
  data() {
    return {
      modals: {
        email: false
      }
    };
  },
  async mounted() {
    await this.getUsers();
  },
  beforeDestroy() {
    this.clearStore();
  },
  computed: {
    ...mapGetters({
      loading: 'admin/loading',
      error: 'admin/error',
      emails: 'admin/emails',
      users: 'admin/users'
    }),
    columns() {
      return [
        {
          name: 'Service',
          code: 'service',
          search: true,
          searchType: 'dropdown',
          searchOptions: serviceOptions().filter(service => service.category === 'company')
        },
        { name: 'From Address', code: 'from' },
        { name: 'To Addresses', code: 'destinations' },
        { name: 'Company', code: 'companyId' },
        { name: 'Received At', code: 'createdAt' },
        { name: '', code: 'actions', noSort: true }
      ];
    }
  },
  methods: {
    ...mapActions({
      getEmails: 'admin/getEmails',
      getUsers: 'admin/getUsers',
      downloadEmailAttachment: 'admin/downloadEmailAttachment'
    }),
    ...mapMutations({
      clearStore: 'admin/CLEAR_STORE'
    }),
    handleType(type) {
      if (type === 'restore') {
        return 'Restored';
      } else if (type === 'delete') {
        return 'Deletion';
      }
      return type;
    },
    handleTypeClass(type) {
      if (type === 'delete') {
        return {
          'badge-danger': true
        };
      }
    },
    handleDetailsTitle(name, value) {
      if (name.length !== 0) {
        return `${value} Ids:`;
      }
    },
    async onClickDownloadAttachment(email, attachment) {
      try {
        const downloadUrl = await this.downloadEmailAttachment({
          id: email._id,
          attachmentId: attachment._id
        });

        if (!downloadUrl || this.error.downloadEmailAttachment) {
          throw new Error('Could not download attachment');
        }

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrl;
        link.download = attachment.filename;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download attachment', { position: 'bottom-center', duration: 3000 });
      }
    }
  }
};
</script>
